import React from "react";
import { Link } from "gatsby";
import Head from "../components/head";
import { graphql } from "gatsby";
import TssUgaStyles from "./programs/tssUga.module.css";

import genericUser from "../images/genericUser.png";

export const query = graphql`
  query {
    allContentfulBio {
      edges {
        node {
          name
          bioImage {
            file {
              url
            }
          }
          titleOrInstrument
        }
      }
    }
  }
`;

class bios extends React.Component {
  render() {
    return (
      <>
      <Head title="TSS bios"></Head>
      <div
        className={TssUgaStyles.UgaScholarContainer}
        style={{ margin: "0 auto" }}
      >
        <h1>All TSS Bios</h1>
        <div>
          {this.props.data.allContentfulBio.edges.map((edge, index) => {
            return (
              <div key={`ugaScholar${index}`} className={TssUgaStyles.scholar}>
                <Link to={`/bio/${edge.node.name}`}>
                  <div>
                    {edge.node.bioImage === null ? (
                      <img src={genericUser}></img>
                    ) : (
                      <img src={edge.node.bioImage.file.url}></img>
                    )}
                    <div>
                      <h4>{edge.node.name}</h4>
                      <p>{edge.node.titleOrInstrument}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      </>
    );
  }
}
export default bios;
